import Head from "next/head";

import "semantic-ui-css/semantic.min.css";

import Hero from "./Hero";

export default function Home() {
  return (
    <div>
      <Head>
        <title>Shaivam</title>
        <meta name="description" content="Abode of God Shiva on the Internet" />
        <link rel="icon" href="/assests/icons/logo.png" />
      </Head>
      <Hero />
    </div>
  );
}
